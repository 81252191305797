import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const DESTROY_GUEST_BOOKMARK = gql`
  mutation DestroyGuestBookmark($guestBookmarkId: ID!) {
    destroyGuestBookmark(input: { guestBookmarkId: $guestBookmarkId }) {
      errors
    }
  }
`;

export function destroyGuestBookmark(appoloClient: ApolloClient<NormalizedCacheObject>, guestBookmarkId: string, callback: (response: ApiResponse) => void): void {
  appoloClient.mutate({ mutation: DESTROY_GUEST_BOOKMARK, variables: { guestBookmarkId } })
    .then(response => {
      const errors = response.data.destroyGuestBookmark.errors;
      callback({ errors, success: !errors || errors.length === 0 });
    })
    .catch(error => {
      callback({ errors: [error.message], success: false });
    });
}
