import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const TOGGLE_MUTED_MESSAGES_ROOM = gql`
  mutation ToggleMutedMessagesRoom($roomId: ID!) {
    toggleMutedMessagesRoom(input: {roomId: $roomId}) {
      errors
    }
  }
`;

export function toggleMutedMessagesRoom(appoloClient: ApolloClient<NormalizedCacheObject>, roomId: string, callback: (response: ApiResponse) => void): void {
  appoloClient.mutate({ mutation: TOGGLE_MUTED_MESSAGES_ROOM, variables: { roomId } })
    .then(results => {
      const errors = results.data.toggleMutedMessagesRoom.errors;
      callback({ errors, success: !errors || errors.length === 0 });
    })
    .catch(error => {
      callback({ errors: [error.message], success: false });
    });
}
