import { apolloClient, apolloClientWithFileUpload } from "../themes/utils/apolloClient";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ApiResponse, StatsField } from "./types";

import { toggleMutedMessagesRoom } from "./mutations/toggleMutedMessagesRoom";
import { rateGuestProduct } from "./mutations/rateGuestProduct";
import { rateGuest } from "./mutations/rateGuest";
import { createGuestBookmark } from "./mutations/createGuestBookmark";
import { submitContactDetailsToExhibitor } from "./mutations/submitContactDetailsToExhibitor";
import { destroyGuestBookmark } from "./mutations/destroyGuestBookmark";
import { createLinkedGuestsImport } from "./mutations/createLinkedGuestsImport";
import { createLeadsExport } from "./mutations/createLeadsExport";
import { createVisitRouteExport } from "./mutations/createVisitRouteExport";
import { fetchGuestStats } from "./queries/fetchGuestStats";
import { fetchLeadQualificationFields } from "./queries/fetchLeadQualificationFields";
import { manageLeadQualificationFields } from "./mutations/manageLeadQualificationFields";
import { createGuestProductInformationRequest } from "./mutations/createGuestProductInformationRequest";

export default class ApiClient {
  private client: ApolloClient<NormalizedCacheObject>;
  private uploadClient: ApolloClient<NormalizedCacheObject>;

  constructor(token: string) {
    this.client = apolloClient(token);
    this.uploadClient = apolloClientWithFileUpload(token);
  }

  toggleMutedMessagesRoom(roomId: string, callback: (response: ApiResponse) => void): void {
    return toggleMutedMessagesRoom(this.client, roomId, callback);
  }

  rateGuestProduct(id: string, rating: number, callback: (response: ApiResponse) => void): void {
    return rateGuestProduct(this.client, id, rating, callback);
  }

  rateGuest(id: string, args: any, callback: (response: ApiResponse) => void): void {
    return rateGuest(this.client, id, args, callback);
  }

  createLinkedGuestsImport(file: any, guestCategoryId: string, subformId: string, locale: string, callback: (response: ApiResponse) => void): void {
    return createLinkedGuestsImport(this.uploadClient, file, guestCategoryId, subformId, locale, callback);
  }

  createGuestProductBookmark(guestProductId: string, callback: (response: ApiResponse) => void): void {
    return createGuestBookmark(this.client, "GuestProduct", guestProductId, callback);
  }

  createExhibitorBookmark(guestId: string, callback: (response: ApiResponse) => void): void {
    return createGuestBookmark(this.client, "Guest", guestId, callback);
  }

  createSessionBookmark(accesspointId: string, callback: (response: ApiResponse) => void): void {
    return createGuestBookmark(this.client, "Accesspoint", accesspointId, callback);
  }

  destroyGuestBookmark(guestBookmarkId: string, callback: (response: ApiResponse) => void): void {
    return destroyGuestBookmark(this.client, guestBookmarkId, callback);
  }

  submitContactDetailsToExhibitor(guestId: string, callback: (response: ApiResponse) => void): void {
    return submitContactDetailsToExhibitor(this.client, guestId, callback);
  }

  createLeadsExport(callback: (response: ApiResponse) => void): void {
    return createLeadsExport(this.client, callback);
  }

  createVisitRouteExport(callback: (response: ApiResponse) => void): void {
    return createVisitRouteExport(this.client, callback);
  }

  fetchGuestStats(statsField: StatsField, callback: (response: ApiResponse) => void): void {
    fetchGuestStats(this.client, statsField, callback);
  }

  fetchLeadQualificationFields(callback: (response: ApiResponse) => void): void {
    fetchLeadQualificationFields(this.client, callback);
  }

  manageLeadQualificationFields(leadQualificationFieldsAttributes, callback: (response: ApiResponse) => void): void {
    manageLeadQualificationFields(this.client, leadQualificationFieldsAttributes, callback);
  }

  createGuestProductInformationRequest(guestProductId: string, requestMessage: string, callback: (response: ApiResponse) => void): void {
    return createGuestProductInformationRequest(this.client, guestProductId, requestMessage, callback);
  }
}
