import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse, BookmarkableType } from "../types";

const CREATE_GUEST_BOOKMARK = gql`
  mutation CreateGuestBookmark($bookmarkableType: String!, $bookmarkableId: ID!) {
    createGuestBookmark(input: { bookmarkableType: $bookmarkableType, bookmarkableId: $bookmarkableId }) {
      guestBookmark {
        bookmarkableId
        bookmarkableType
        guestId
        id
      }
      errors
    }
  }
`;

export function createGuestBookmark(appoloClient: ApolloClient<NormalizedCacheObject>, bookmarkableType: BookmarkableType, bookmarkableId: string, callback: (response: ApiResponse) => void): void {
  appoloClient.mutate({ mutation: CREATE_GUEST_BOOKMARK, variables: { bookmarkableType, bookmarkableId } })
    .then(response => {
      const errors = response.data.createGuestBookmark.errors;
      const data = response.data.createGuestBookmark.guestBookmark;
      callback({ data, errors, success: !errors || errors.length === 0 });
    })
    .catch(error => {
      callback({ errors: [error.message], success: false });
    });
}
