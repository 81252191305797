import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const QUERY = gql`
  query LinkedGuestsImport($id: ID!) {
    viewer {
      linkedGuestsImport(id: $id) {
        parseStatus
        parseError
        createdLinkedGuestsCount
        updatedLinkedGuestsCount
        importValidationErrors {
          lineNumber
          columns
          validationErrors
        }
      }
    }
  }
`;

export function fetchLinkedGuestsImport(apolloClient: ApolloClient<NormalizedCacheObject>, id: string, callback: (response: ApiResponse) => void): void {
  apolloClient.query({
    query: QUERY,
    variables: {
      id
    },
    fetchPolicy: "network-only"
  }).then(results => {
    const errors = results.errors;
    const data = results.data.viewer.linkedGuestsImport;
    callback({ data, errors, success: !errors || errors.length === 0 });
  }).catch(error => {
    callback({ errors: [error.message], success: false });
  });
}
