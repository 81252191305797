import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";
import { fetchLinkedGuestsImport } from "../queries/fetchLinkedGuestsImport";

const MUTATION = gql`
  mutation CreateLinkedGuestsImport($file: Upload, $guestCategoryId: ID!, $subformId: ID!, $locale: String) {
    createLinkedGuestsImport(input: { file: $file, guestCategoryId: $guestCategoryId, subformId: $subformId, locale: $locale }) {
      import {
        id
      }
      errors
    }
  }
`;

export function createLinkedGuestsImport(apolloClient: ApolloClient<NormalizedCacheObject>, file: any, guestCategoryId: string, subformId: string, locale: string, callback: (response: ApiResponse) => void): void {
  apolloClient.mutate({
    mutation: MUTATION,
    variables: {
      file,
      guestCategoryId,
      subformId,
      locale
    }
  }).then(results => {
    const errors = results.data.createLinkedGuestsImport.errors;
    const data = results.data.createLinkedGuestsImport.import;

    if (!errors || errors.length === 0) {
      const requestsInterval = setInterval(() => {
        fetchLinkedGuestsImport(apolloClient, data.id, (response: ApiResponse) => {
          const { success, data, errors } = response;

          if (success) {
            const { parseStatus } = data;
            if (parseStatus == 6 || parseStatus == 10) {
              callback({ data, success: true });
              clearInterval(requestsInterval);
            }
          } else {
            callback({ data, success: false, errors });
            clearInterval(requestsInterval);
          }
        });
      }, 3000);
    } else {
      callback({ data, success: false, errors });
    }
  }).catch(error => {
    callback({ errors: [error.message], success: false });
  });
}
