import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse, StatsField } from "../types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LEADS_STATS_FIELDS = `
  leadsCount
  avgRating
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INVITATIONS_STATS_FIELDS = `
  sentInvitationsCount
  registeredInvitationsCount
  showedUpInvitationsCount
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PRODUCTS_STATS_FIELDS = `
  productsCount
  avgBookmarks
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SESSIONS_STATS_FIELDS = `
  sessionsCount
  avgReservations
  avgBookmarks
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GLOBAL_STATS_FIELDS = `
  bookmarksCount
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PHYSICAL_MEETINGS_STATS_FIELDS = `
  requestedMeetingsCount
  confirmedMeetingsCount
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LIVE_MEETINGS_STATS_FIELDS = `
  requestedMeetingsCount
  confirmedMeetingsCount
  unfoldedMeetingsCount
  avgDuration
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LEADS_DETAILS_FIELDS = `
  roles {
    label
    leadQualificationsCount
  }
  types {
    label
    leadQualificationsCount
  }
  sources {
    label
    leadsCount
  }
  authors {
    label
    leadQualificationsCount
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PRODUCTS_DETAILS_FIELDS = `
  products {
    name
    bookmarksCount
    ratingsCount
    avgRating
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SESSIONS_DETAILS_FIELDS = `
  sessions {
    name
    bookmarksCount
  }
`;

function gqlQuery(statsField: StatsField): any {
  return gql`
    query GuestStats($statsField: String!) {
      viewer {
        guestStats(statsField: $statsField) {
          ${eval(`${statsField.toUpperCase()}_FIELDS`)}
        }
      }
    }
  `;
}

export function fetchGuestStats(apolloClient: ApolloClient<NormalizedCacheObject>, statsField: StatsField, callback: (response: ApiResponse) => void): void {
  apolloClient.query({
    query: gqlQuery(statsField),
    variables: {
      statsField
    },
    fetchPolicy: "network-only"
  }).then(results => {
    const errors = results.errors;
    const data = results.data.viewer.guestStats;
    callback({ data, errors, success: !errors || errors.length === 0 });
  }).catch(error => {
    callback({ errors: [error.message], success: false });
  });
}
