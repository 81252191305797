import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const SUBMIT_CONTACT_DETAILS_TO_EXHIBITOR = gql`
  mutation SubmitContactDetailsToExhibitor($guestId: ID!) {
    submitContactDetailsToExhibitor(input: { guestId: $guestId }) {
      errors
    }
  }
`;

export function submitContactDetailsToExhibitor(appoloClient: ApolloClient<NormalizedCacheObject>, guestId: string, callback: (response: ApiResponse) => void): void {
  appoloClient.mutate({ mutation: SUBMIT_CONTACT_DETAILS_TO_EXHIBITOR, variables: { guestId } })
    .then(response => {
      const errors = response.data.submitContactDetailsToExhibitor.errors;
      callback({ errors, success: !errors || errors.length === 0 });
    })
    .catch(error => {
      callback({ errors: [error.message], success: false });
    });
}
