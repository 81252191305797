import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const CREATE_GUEST_PRODUCT_INFORMATION_REQUEST = gql`
  mutation createGuestProductInformationRequest($guestProductId: ID!, $requestMessage: String!) {
    createGuestProductInformationRequest(input: { guestProductId: $guestProductId , requestMessage: $requestMessage }) {
      errors
      guestProductInformationRequest {
        id
        requestMessage
        responseMessage
        requester {
          id
        }
        status
      }
    }
  }
`;

export function createGuestProductInformationRequest(apolloClient: ApolloClient<NormalizedCacheObject>, guestProductId: string, requestMessage: string, callback: (response: ApiResponse) => void): void {
  apolloClient.mutate({
    mutation: CREATE_GUEST_PRODUCT_INFORMATION_REQUEST,
    variables: { guestProductId, requestMessage }
  })
    .then(response => {
      const errors = response.data.createGuestProductInformationRequest.errors;
      const data = null;
      callback({ data, errors, success: !errors || errors.length === 0 });
    })
    .catch(error => {
      callback({ errors: [error.message], success: false });
    });
}
