import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const CREATE_LEADS_EXPORT = gql`
  mutation {
    createLeadsExport(input: {}) {
      errors
    }
  }
`;

export function createLeadsExport(appoloClient: ApolloClient<NormalizedCacheObject>, callback: (response: ApiResponse) => void): void {
  appoloClient.mutate({ mutation: CREATE_LEADS_EXPORT })
    .then(response => {
      const errors = response.data.createLeadsExport.errors;
      const data = null;
      callback({ data, errors, success: !errors || errors.length === 0 });
    })
    .catch(error => {
      callback({ errors: [error.message], success: false });
    });
}
