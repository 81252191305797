import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const MUTATION = gql`
  mutation CreateGuestRating($assessableId: ID!, $assessableType: String!, $rating: Int, $message: String) {
    createGuestRating(input: { assessableId: $assessableId, assessableType: $assessableType, rating: $rating, message: $message }) {
      errors
    }
  }
`;

export function rateGuest(appoloClient: ApolloClient<NormalizedCacheObject>, id: string, args: any, callback: (response: ApiResponse) => void): void {
  appoloClient.mutate({
    mutation: MUTATION,
    variables: {
      assessableId: id,
      assessableType: "Guest",
      ...args
    }
  }).then(results => {
    const errors = results.data.createGuestRating.errors;
    callback({ errors, success: !errors || errors.length === 0 });
  }).catch(error => {
    callback({ errors: [error.message], success: false });
  });
}
