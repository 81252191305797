import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const gqlQuery: any = gql`
  query fetchLeadQualificationFields {
    viewer {
      leadQualificationFields {
        id
        label
        type
        possibleValues
        hidden
      }
    }
  }
`;

export function fetchLeadQualificationFields(apolloClient: ApolloClient<NormalizedCacheObject>, callback: (response: ApiResponse) => void): void {
  apolloClient.query({
    query: gqlQuery,
    fetchPolicy: "network-only"
  }).then(results => {
    const errors = results.errors;
    const data = results.data.viewer.leadQualificationFields;
    callback({ data, errors, success: !errors || errors.length === 0 });
  }).catch(error => {
    callback({ errors: [error.message], success: false });
  });
}
