import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
  ApolloLink
} from "@apollo/client";

import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

const ENDPOINT = "/api/graphql";

function endpointWithLocale(eventId: string): string {
  const endpoint = `${ENDPOINT}?locale=${I18n.locale}`;
  return eventId ? endpoint + `&event_id=${eventId}` : endpoint;
}

function createApolloClient(token: string, link: ApolloLink): ApolloClient<NormalizedCacheObject> {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

  return new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache()
  });
}

export function apolloClient(token: string, eventId: string = null): ApolloClient<NormalizedCacheObject> {
  return createApolloClient(token, createHttpLink({ uri: endpointWithLocale(eventId) }));
}
export function apolloClientWithFileUpload(token: string, eventId: string = null): ApolloClient<NormalizedCacheObject> {
  return createApolloClient(token, createUploadLink({ uri: endpointWithLocale(eventId) }));
}
