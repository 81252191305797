import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
import { ApiResponse } from "../types";

const MANAGE_LEAD_QUALIFICATION_FIELDS = gql`
  mutation ManageLeadQualificationField($leadQualificationFieldsAttributes: [LeadQualificationFieldInput!]!) {
    manageLeadQualificationField(input: { leadQualificationFieldsAttributes: $leadQualificationFieldsAttributes }) {
      leadQualificationFields {
        id
        label
        type
        hidden
        possibleValues
      }
      errors
    }
  }
`;

export function manageLeadQualificationFields(appoloClient: ApolloClient<NormalizedCacheObject>, leadQualificationFieldsAttributes, callback: (response: ApiResponse) => void): void {
  appoloClient.mutate({
    mutation: MANAGE_LEAD_QUALIFICATION_FIELDS,
    variables: { leadQualificationFieldsAttributes }
  })
    .then(response => {
      const errors = response.data.manageLeadQualificationField.errors;
      const data = response.data.manageLeadQualificationField.leadQualificationFields;
      callback({ data, errors, success: !errors || errors.length === 0 });
    })
    .catch(error => {
      callback({ errors: [error.message], success: false });
    });
}
